<template>
	<div class="ybd-view">
		<ucenterHeader ></ucenterHeader>
		<div class="container">
			<ucenterLeftNav></ucenterLeftNav>
			<div class="main">
				<div class="u-rightbar">
					<div class="module-title">
						<div><i></i><span>邮箱设置</span></div>
					</div>
					<el-form ref="form" :model="form" :rules="rules" label-width="140px">
						<el-form-item prop="emailType" label="邮件服务器类型">
							<el-select v-model="form.emailType">
								<el-option :value="1" label="腾讯企业邮箱"></el-option>
								<el-option :value="2" label="腾讯QQ邮箱"></el-option>
								<el-option :value="3" label="网易163邮箱"></el-option>
								<el-option :value="4" label="自定义邮箱"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item prop="receiveMail" label="收件人名称">
							<el-input v-model="form.receiveMail" placeholder="请输入收件人名称" maxlength="50"></el-input>
						</el-form-item>
						<el-form-item prop="addresser" label="发件人名称">
							<el-input v-model="form.addresser" placeholder="请输入发件人名称" maxlength="50"></el-input>
						</el-form-item>
						<el-form-item prop="emailServer" label="邮箱服务器">
							<el-input v-model="form.emailServer" placeholder="请输入邮箱服务器" maxlength="50"></el-input>
						</el-form-item>
						<el-form-item prop="port" label="邮件端口">
							<el-input v-model="form.port" placeholder="请输入邮件端口" maxlength="20"></el-input>
						</el-form-item>
						<el-form-item prop="emailName" label="邮件用户名">
							<el-input v-model="form.emailName" placeholder="请输入邮件用户名" maxlength="50"></el-input>
						</el-form-item>
						<el-form-item prop="password" label="邮件授权密码">
							<el-input v-model="form.password" type="password" placeholder="请输入邮件授权密码" maxlength="50"></el-input>
							<el-popover placement="top-start" width="400" trigger="hover">
								<div class="tips-main clearfix">
									<p>腾讯企业邮箱：不要再用你开通邮箱时设置的密码了，要用提示你微信绑定时，自动给你设置的客户端专用密码；</p>
									<p>腾讯QQ邮箱：不能使用邮箱登录密码，需要在邮箱中开启POP3/SMTP服务，开启后邮箱会给你一个授权码；</p>
									<p>网易163邮箱：不能使用邮箱登录密码，需要在邮箱中开启POP3/SMTP服务（默认是开启的），开启后并在授权密码管理处设置授权码。</p>
								</div>
								<i slot="reference" class="el-icon-question"></i>
							</el-popover>
						</el-form-item>
						<el-form-item prop="coding" label="邮件编码">
							<el-input v-model="form.coding" placeholder="请输入邮件编码如：utf-8"></el-input>
						</el-form-item>
						<el-form-item >
							<el-button type="primary" @click="save('form')">确定</el-button>
							<el-button @click="test('form')">测试</el-button>
						</el-form-item>
					</el-form>
				</div>	
					
				
			</div>
		</div>
	</div>
</template>
<script>
import { get,post } from "/src/services/ajax_ucenter.js";
import ucenterHeader from "@/components/ucenterHeader.vue";
import ucenterLeftNav from "@/components/ucenterLeftNav.vue";
export default {
	components: {
		ucenterHeader,
		ucenterLeftNav,
		
	},
	data(){
		return {
			form:{},
			rules:{
				emailType:[
					{required:true,message:'请选择邮件服务器类型！',trigger:'blur'}
				],
				receiveMail:[
					{required:true,message:'请输入收件人名称！',trigger:'blur'}
				],
				addresser:[
					{required:true,message:'请输入发件人名称！',trigger:'blur'}
				],
				emailServer:[
					{required:true,message:'请输入邮箱服务器！',trigger:'blur'}
				],
				port:[
					{required:true,message:'请输入邮件端口！',trigger:'blur'}
				],
				emailName:[
					{required:true,message:'请输入邮件用户名！',trigger:'blur'}
				],
				password:[
					{required:true,message:'请输入邮件授权密码！',trigger:'blur'}
				],
				coding:[
					{required:true,message:'请输入邮件编码！',trigger:'blur'}
				]
			}
		}
	},
	created(){
		this.getDetail()
	},
	computed: {
	},
	watch:{
	},
	mounted(){
		
	},
	methods: {
		// 获取设置
		getDetail(){
			const _this = this;
			get('/formadmin/emailSet/email_set_list.jhtml',{}).then(res => {
				if(res.code == 200){
					_this.form = res.data.entity;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 测试
		test(form){
			const _this = this;
			_this.$refs[form].validate(valid=>{
				if(valid){
					get('/formadmin/emailSet/test_emailSet.jhtml',_this.form).then(res => {
						if(res.code == 200){
							_this.$message.success("测试成功！");
							_this.form.testInput = 1;
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}
			})
		},
		// 保存
		save(form){
			const _this = this;
			_this.$refs[form].validate(valid=>{
				if(valid){
					post('/formadmin/emailSet/save_emailSet.jhtml',_this.form).then(res => {
						if(res.code == 200){
							_this.getDetail()
						}else{
							_this.$message.error(res.message);
						}
					}).catch(err => {
						_this.$message.error(err);
					})
				}
			})
			
		},
		
		// 删除
		del(id){
			const _this = this;
			this.$confirm('确定删除吗？删除后不可恢复。', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				get('/formadmin/linkman/linkman_delete.jhtml',{linkmanId:id}).then(res => {
					if(res.code == 200){
						_this.getTplList();
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				return false;
			});
		},
	}
};
</script>
<style lang="less" scoped>
.main {
	display: flex;
	flex-direction: column;
	padding: 20px;
	overflow-y: auto;
	.u-rightbar {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: 20px 30px;
		background: #fff;
		.module-title{
			font-size: 16px;
			line-height: 36px;
			color:#333;
			margin-bottom: 10px;
			padding:0;
			border-bottom:none; 
			display: flex;
			justify-content: space-between;
			i{
				width: 4px;
				height:16px;
				background: var(--theme-color);
				margin-right: 10px;
				display: inline-block;
				vertical-align:-2px;
			}
		}
		.i-ico {
			width: 20px;
			height: 20px;
			cursor: pointer;
			display: inline-block;
			vertical-align: middle;
			background:url("../../../assets/images/icon_main.png") -25px -246px no-repeat;
		}
		.module-btns{
			display: flex;
			justify-content: space-between;
			align-items: center;
			.el-form--inline .el-form-item{
				margin-bottom: 0;
			}
		}
		.el-form{
			width: 400px;
			margin:0 auto;
			.el-input{
				width: 217px;
			}
			.el-popover__reference{
				color: #ccc;
				font-size: 18px;
				margin-left: 10px;
			}
		}
	}		
}
.el-popover .tips-main{
	// padding:20px 18px;
	color:#666;
	p{
		font-size: 12px;
		padding:5px 0;
	}
}
</style>